import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Success from '../views/Success.vue';
import Failure from '../views/Failure.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/success',
    name: 'Success',
    component: Success,
  },
  {
    path: '/failure',
    name: 'Failure',
    component: Failure,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
