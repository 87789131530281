
import { defineComponent } from 'vue';
import SuccessScreen from '@/components/SuccessScreen.vue';

export default defineComponent({
  name: 'SuccessPage',
  components: {
    SuccessScreen,
  },
});
