
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'SuccessScreen',
  setup() {
    const state = reactive({
      consentTitleText: '',
      consentDisplayText: '',
      clientName: 'Microsoft Teams', // TODO: BOT-746: Resolve dynamically
      clientIconUrl: require('../assets/icons/teams-and-company-logo.svg'), // TODO: Resolve dynamically & Separate by Target icon and COMPANY icons
    });

    const closeWindowBtn = () => self.window.close();
    const autoCloseWindow = () => window.setTimeout(closeWindowBtn, 5000);
    autoCloseWindow();

    state.consentTitleText = `${state.clientName} との連携が完了しました`
    state.consentDisplayText = `このウィンドウは 5 秒後に自動的に閉じます。自動的に閉じない場合は手動で閉じてください。`

    return {
      state,
      closeWindowBtn,
      autoCloseWindow,
    };
  },
});
