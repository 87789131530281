
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'FailureScreen',
  setup() {
    const state = reactive({
      consentTitleText: '',
      consentDisplayText: '',
      clientName: 'Microsoft Teams', // TODO: BOT-746: Resolve dynamically
      clientIconUrl: require('../assets/icons/teams-and-company-logo.svg'), // TODO: Resolve dynamically & Separate by Target icon and COMPANY icons
    });

    const closeWindowBtn = () => self.window.close();

    state.consentTitleText = `${state.clientName} との連携を拒否しました`
    state.consentDisplayText = `ウィンドウを閉じてください。連携したい場合は、操作をやり直してください。`

    return {
      state,
      closeWindowBtn,
    };
  },
});
