
import { defineComponent } from 'vue';
import FailureScreen from '@/components/FailureScreen.vue';

export default defineComponent({
  name: 'FailurePage',
  components: {
    FailureScreen,
  },
});
