export const baseColor = {
  black: {
    100: '#ffffff',
    200: '#f4f4f4',
    300: '#eaeaea',
    400: '#d3d3d3',
    500: '#bcbcbc',
    600: '#959595',
    700: '#747474',
    800: '#505050',
    900: '#323232',
    1000: '#000000',
    blueWhi: '#007cc5',
    blueWhiDark: '#00427e',
    // Ref: Neon env labels style
    // https://drive.google.com/file/d/1OakCug666CctL0rPvvv5u4YjzcEc288L/view?usp=sharing
    envLabelBlue: '#59afe1',
  },
};
export const companyTopNav = {
  bgColor: {
    blue1: 'rgba(0, 91, 172, 1)',
    blue2: 'rgba(94,194,198,1)',
  },
};
export const mainColor = {
  bgColor: {
    colorPrimary: '#007cc5',
    colorInfo: '#4285F4',
    colorDefault: '#eaeaea',
    colorSuccess: '#00B19D',
    colorWarning: '#F4B142',
    colorError: '#E91E63',
  },
  fontColor: {
    colorWhite: '#ffffff',
    colorBlack: '#323232',
    colorError: '#E91E63',
  },
};
export const btnColor = {
  bgColor: {
    colorDefault: '#EAEAEA',
    colorDefaultHover: '#D3D3D3',
    colorPrimary: '#007CC5',
    colorPrimaryHover: '#00427E',
  },
  fontColor: {
    colorWhite: '#ffffff',
    colorBlack: '#323232',
  },
};
