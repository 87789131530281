
import { computed, defineComponent } from 'vue';
import 'normalize.css';
import { baseColor, companyTopNav, btnColor } from './theme/neon';

export default defineComponent({
  name: 'App',
  setup() {
    const neonStyle = computed(() => ({
      '--baseColor100': baseColor.black[100],
      '--companyTopNav1': companyTopNav.bgColor.blue1,
      '--companyTopNav2': companyTopNav.bgColor.blue2,
      '--btnColorBgDefault': btnColor.bgColor.colorDefault,
      '--btnColorBgDefaultHover': btnColor.bgColor.colorDefaultHover,
      '--btnColorBgPrimary': btnColor.bgColor.colorPrimary,
      '--btnColorBgPrimaryHover': btnColor.bgColor.colorPrimaryHover,
      '--btnColorFontWhite': btnColor.fontColor.colorWhite,
      '--btnColorFontBlack': btnColor.fontColor.colorBlack,
    }));

    return {
      neonStyle,
    };
  },
});

